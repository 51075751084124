@import "_variables.scss";

.modal-selector-container {
  .modal-selector-tag-container {
    gap: 1.6rem;
    margin-top: 1rem;
  }

  .modal-selector-edit-btn-group {
    gap: 1rem;
    margin-top: 1rem;
    .chakra-button {
      font-size: $font-size-small !important;
    }
  }
}

.modal-selector-active {
  border-color: $color-sidebar-hover-bg !important;
  background-color: $color-sidebar-hover-bg;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
}

.app-modal-content {
  &.app-modal-selector {
    max-width: 800px;
    .chakra-modal__body {
      font-size: $font-size-reg;
    }
    .modal-selector-option-flex {
      gap: 2rem;
      border: 1px solid transparent;
      margin-bottom: 0.6rem;
      padding: 6px;
      .modal-selector-option {
        width: 30%;
      }
      .modal-selector-description {
        width: 70%;
      }
      &.option-selected {
        @extend .modal-selector-active;
      }
      &:hover {
        @extend .modal-selector-active;
        color: $color-link-hover-color !important;
      }
    }

    .chakra-input__group {
      background-color: $color-form-field-bg;
      margin-bottom: 1.5rem;
      .chakra-input__left-element {
        height: 48px;
        margin: 0px 6px;
        svg {
          font-size: $font-size-reg;
          color: $color-default-font-color;
        }
      }
      .chakra-input {
        height: 48px;
        font-size: $font-size-reg;
        padding-left: 40px;
        border-color: $color-divider;
      }
    }

    .modal-selector-selected-options {
      margin-top: 24px;
    }
  }
}

//to be moved to tag component
.app-tags {
  padding: 7px 12px;
  min-width: 100px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  margin-right: 16px;
  svg {
    font-size: $font-size-reg;
  }
}
.app-tag-selected {
  background-color: $color-sidebar-hover-bg;
  color: $color-link-hover-color;
  svg {
    background: $color-link-hover-color;
    color: $color-default-white;
    cursor: pointer;
    border-radius: 20px;
  }
}
