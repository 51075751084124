@import "_variables.scss";

.grid-item{
    display: flex;
    flex-direction: column;
    padding: var(--chakra-space-12);
    gap: var(--chakra-space-12);
    &.first-section{
        width: 80%;
    }

    .badge-divider{
        width: 288px;
        border: 1px solid $color-border;
    }
  
  .horizontalLineBottom {
    position: relative;
    width: 100%;
    margin-top: var(--chakra-space-9-6);
    bottom: 0;
    border: 1px solid $color-border;
  }

  .examText {
    font-family: $font-family-regular;
    font-size: $font-size-2rem;
    font-weight: $font-weight-600;
    line-height: var(--chakra-space-16);
    text-align: left;
    display: flex;
    gap: var(--chakra-space-2);
    align-items: center;
    padding-top: var(--chakra-space-2);
    z-index: 2;
    .back-btn {
      background: none;
      padding: 0;
    }
    position: relative;
  }
  .progress {
    font-weight: $font-weight-medium;
    font-size: $font-size-reg;
    line-height: $line-height-medium;
    color: $color-default-font-color;
    top: 100%;
    right: 0;
  }
}
