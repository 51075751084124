@import "_variables.scss";

.doc-list-container {
  overflow-y: auto;
  .chakra-card__body {
    padding: 0px;
  }
  .doc-heading {
    font-family: $font-family-semi-bold;
    font-size: $font-size-l;
    font-weight: var(--chakra-fontWeights-semibold);
  }
  .doc-sub-heading {
    font-size: $font-size-reg;
    font-weight: var(--chakra-fontWeights-normal);
    color: $color-font-card;
    margin-bottom: 0px;
  }
}
