@import "_variables.scss";

.academy-catalog-container {
  .learning-catalog-header-container {
    border-bottom: 1px solid $color-gradient-light-orange;
    align-items: start;
    padding: $padding-large;

    .main-title {
      font-size: $font-size-xs;
      font-family: $font-family-sfmono;
      font-weight: $font-weight-medium;
      color: $color-font-card;
    }

    .page-title {
      font-size: $font-size-xl;
      font-weight: $font-weight-semi-bold;
    }

    .page-subtitle {
      font-size: $font-size-med;
      color: $color-font-card;
    }
  }

  .learning-catalog-filter-container {
    .accordion-button {
      border-bottom: 0.5px solid $color-divider;
      min-width: $catalog-min-width;
    }

    .filter-group {
      border-bottom: 0.5px solid $color-divider;
      margin-bottom: var(--chakra-space-6-4);
      padding-bottom: var(--chakra-space-6-4);
      gap: var(--chakra-space-6-4);
    }
  }

  .catalog-container {
    align-items: start;
    width: 100%;
    padding: var(--chakra-space-12-8);
    row-gap: var(--chakra-space-12-8);

    .catalog-card-container {
      flex-wrap: wrap;
      gap: var(--chakra-space-12-8);
      width: 100%;
    }

    .sorting-box {
      padding-left: $catalog-left-padding;
    }

    .catalog-sorting-container {
      width: $catalog-expanded-width;
      max-width: $catalog-max-width;

      .catalog-sorting-select {
        width: 100%;
        max-width: $catalog-mid-width;
        background-color: $color-default-white;
        cursor: pointer;

        .optionContainer {
          margin-left: 0;

          .option:hover {
            background-color: $color-bg-badge;
          }

          .option.selected {
            background-color: $color-btn-secondary-hover;
            border-radius: $border-radius-small;

            .option-value::after {
              content: "\2713";
              width: 2rem;
              height: 2rem;
              right: 12%;
              color: $color-check-background;
              position: absolute;
            }
          }
        }
        .icon_down_dir {
          top: var(--chakra-space-9-6) !important;
        }
      }
    }
  }

  .no-results-found-section {
    h3 {
      font-size: $font-size-l;
    }

    p {
      font-size: $font-size-reg;
    }
  }
}

@media (max-width: $screen-width-breakpoint-1) {
  .academy-catalog-container .catalog-container .sorting-box {
    padding-left: $catalog-screen-padding-max;
  }
}

@media (max-width: $screen-width-breakpoint-2) {
  .academy-catalog-container .catalog-container .sorting-box {
    padding-left: $catalog-screen-padding-min;
  }
}
