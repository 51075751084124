@import "_variables.scss";

.date-time-seperator {
  display: flex;
  gap: 2rem;
  div {
    flex-basis: 50%;
  }
}

.main-title-style {
  font-family: $font-family-regular;
}
.app-form-field-header {
  font-family: $font-family-regular;
  font-size: $font-size-l;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-xlarge;
}

.app-form-field-sub-header {
  p{
    color: $color-font-card;
  }
}
