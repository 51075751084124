@import "_variables.scss";

.main-title-style {
  font-family: $font-family-regular;
}
.app-form-container{
  .card-title {
    font-family: $font-family-regular;
    font-size: $font-size-l;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-xlarge;
  }
}

.add-new-button {
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-small;
  color: $color-font-card;
}