@import "_variables.scss";

.share-guidelines {
  text-align: justify;
  font-size: $font-size-reg;
  line-height: $line-height-medium;
  img {
    margin: var(--chakra-space-8) auto;
    width: 1000px;
    height: auto;
    &.icon {
      width: var(--chakra-space-12);
    }
  }
  .info-box {
    width: var(--chakra-space-9-6);
    height: var(--chakra-space-9-6);
    img {
      width: var(--chakra-space-9-6);
      height: var(--chakra-space-9-6);
      margin: 0;
    }
  }
  .announcement {
    background-color: $color-sidebar-hover-bg;
    border-radius: $border-radius-medium;
  }
}

.bold {
  font-weight: $font-weight-700;
}

.italics {
  font-style: italic;
}

.guidelines {
  margin: 0 var(--chakra-space-12);
  li a {
    color: $color-link-hover-color;
    &:hover {
      color: $color-btn-link-hover !important;
      text-decoration: underline !important;
    }
  }
}

.json-holder {
  display: flex;
  gap: var(--chakra-space-5);
  .copy-btn {
    background: none;
    padding: 0;
    &:hover {
      background-color: $color-border-hover;
    }
  }
}
