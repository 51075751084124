@import "_variables.scss";

.app-form-field-header {
  font-family: $font-family-regular;
  font-size: $font-size-l;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-xlarge;
  color: $color-default-font-color;
}

.app-form-field-sub-header {
  color: $color-font-card;
}
.main-title-style {
  font-family: $font-family-regular;
}
