@import "_variables.scss";

.input-width-small {
  width: 25%;
}
.add-new-button-relationship {
  font-family: $font-family-semi-bold;
  font-size: $font-size-small;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-small;
  letter-spacing: 0.01em;
  text-align: left;
  color: $color-font-card;
}
