@import "_variables.scss";

.navigation-link {
  display: flex;
  column-gap: var(--chakra-space-3);
  justify-content: flex-start;
  align-items: center;
}

.environment-container {
  margin-bottom: var(--chakra-space-12-8);
  margin-top: var(--chakra-space-16);
  border-bottom: 1px solid $color-border;
  .environment-heading {
    gap: var(--chakra-space-4);
    margin-bottom: var(--chakra-space-8);
    h3 {
      font-size: $font-size-med;
      font-weight: $font-weight-semi-bold;
      margin-bottom: 0;
    }
  }
}

.access-management-remove-app-modal {
  .app-modal-body {
    padding: var(--chakra-space-7) !important;
  }
  .app-modal-title {
    font-size: var(--chakra-fontSizes-3xl) !important;
    font-weight: var(--chakra-fontWeights-semibold) !important;
  }
  footer {
    gap: var(--chakra-space-10) !important;
    margin-top: 0 !important;
  }
}

.override-container {
  border-top: 1px solid $color-border;
  padding-top: var(--chakra-space-7);
  margin-top: var(--chakra-space-7);
}

.reset-button {
  word-break: initial;
  font-size: var(--chakra-fontSizes-2xl);
}

.password-input {
  border-color: transparent;
  width: auto;
  :hover {
    border-color: transparent;
  }
}

.icon-button {
  background: none;
}
