@import "_variables.scss";
.access-management-details-container {
  img {
    cursor: pointer;
    margin-left: var(--chakra-space-12);
  }
}
.custom-roles-container {
  width: 100%;
  margin-bottom: var(--chakra-space-20);
  .roles-selection-radio-group {
    max-width: 90%;
  }
  .core-role-desc {
    font-size: $font-size-reg;
  }
  .app-form-field-container.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: none;
  }
}
.multiselect-checkbox {
  max-height: 332px;
  overflow: scroll;
  padding: var(--chakra-space-1);
  border-radius: $border-radius-small;
  border: 1px solid $color-border;
  .custom-table-header-data-item {
    width: 100% !important;
    .custom-table-header-checkbox {
      width: 100%;
      .chakra-checkbox__control {
        border-radius: 4px !important;
      }
    }
  }
}
