@import "_variables.scss";

.detail-item-divider {
  border: 1px solid $color-nav-selected;
}
.menu-list-container {
  width: 120px; // Width of the dropdown menu
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0 4px 8px $color-toast-box-shadow;
  border-radius: $border-radius-large;
  padding: var(--chakra-space-4);

  button {
    padding: var(--chakra-space-6-4);
    font-size: $font-size-reg;
    font-weight: var(--chakra-fontWeights-bold);
    &:hover {
      background-color: $color-sidebar-hover-bg;
      border-radius: $border-radius-small;
    }
    &:focus {
      background-color: $color-sidebar-hover-bg;
      border-radius: $border-radius-small;
    }
  }
}
