@import "_variables.scss";

.certificate-status-container{
    box-shadow: $box-shadow-default;
    .certificate-status-completion{
        background-color: $color-sidebar-hover-bg;
        border-radius: calc(var(--chakra-space-6) + 1px) calc(var(--chakra-space-6) + 1px) 0 0;
        
        .action-button-holder{
            display: flex;
            flex-direction: column;
            gap: calc(var(--chakra-space-7) + 0.5px);

            .action-button{
                .action-button-icon{
                    margin-right: calc(var(--chakra-space-3) + 0.5px);
                }
            }
        }
    }
    .certificate-validity-holder{
        .certificate-validity-intro{
            font-size: calc(var(--chakra-space-7) + 0.5px);
            font-weight: $font-weight-600;
            line-height: calc(var(--chakra-space-9) + 1.5px);
        }
        .certificate-validity-details{
            display: flex;
            flex-direction: column;
        }
        .certificate-validity-details-holder{
            display: flex;
            align-items: baseline;
            gap: 6px;
            margin: calc(var(--chakra-space-3) + 0.5px) 0;
            .detail-text{
                font-size: var(--chakra-space-5);
                font-weight: $font-weight-400;
                line-height: calc(var(--chakra-space-6) + 1px);
            }
            .detail-text-bold{
                font-size: calc(var(--chakra-space-5) + 1.5px);
                font-weight: $font-weight-600;
                line-height: var(--chakra-space-8);
            }
            .status-tag{
                &.active{
                    background-color: $color-toast-success-notification;
                }
                &.inactive{
                    background-color: $color-default-warning,
                }
            }
        }
        .cert-expiry-tag{
            height: calc(var(--chakra-space-12) + 2px);
            width: var(--chakra-space-60);
            font-family: $font-family-sfmono;
            font-size: calc(var(--chakra-space-5) + 1.5px);
            line-height: var(--chakra-space-8);
            background-color: $color-bg-badge-warning;
            color: $color-default-font-color;
            .chakra-icon{
                color: $color-default-warning;
                background-color: $color-default-black ;
                border-radius: calc(var(--chakra-space-5) + 1.5px);
            }
        }
    }
}
