@import "_variables.scss";

.userprofile-container {
  padding: 1rem 3rem 3rem 3rem;
  .name {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: $color-default-font-color;
  }
  .organization {
    font-family: $font-family-sfmono;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: $color-font-card;
  }
  .accomplishment-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 5px;
  }
  .profile-divider {
    color: $color-border;
    opacity: 1;
    margin: 10px 0;
  }
}

.avatar-container {
  height: 95px;
  width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-user-avatar-background;
  color: $color-default-white;
  font-weight: 600;
  font-size: 32px;
  border-radius: 95px;
  overflow: hidden;
  object-fit: contain;
}

.accomplishment-container {
  display: flex;
  align-items: center;
  background: $color-bg-badge-success;
  padding: 12px;
  gap: 8px;
  border-radius: 8px;
  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    color: $color-toast-success-highlight;
  }
  .subtitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $color-toast-success-highlight;
  }
  .icon {
    width: 26px;
    height: 30px;
    border-radius: 24px;
  }
}
