@import "_variables.scss";

.academy-payment-modal {
  max-width: 900px;
  max-height: 1100px;
  margin-top: var(--chakra-space-4);
  .chakra-modal__body {
    margin-top: 0;
  }
}
