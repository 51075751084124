@import "_variables.scss";

.tab-component-table {
  thead {
    tr {
      background-color: $color-bg-badge;
      th {
        font-size: $font-size-reg;
        font-weight: var(--chakra-fontWeights-small);
        text-transform: none;
        color: $color-default-black;
        padding: var(--chakra-space-4) var(--chakra-space-4);
      }
      .custom-table-header-data-item {
        font-size: $font-size-reg !important;
        width: 100% !important;
      }
      .custom-table-header-data {
        cursor: pointer;
      }
    }
  }

  tbody {
    .menu-list-container {
      width: var(--chakra-space-4);
      border-radius: var(--chakra-radii-3xl);
      box-shadow: 0 var(--chakra-space-2) var(--chakra-space-6) 0
        $color-box-shadow;
      padding: var(--chakra-space-4);
      button {
        padding: var(--chakra-space-4);
        font-size: $font-size-reg;
        font-weight: var(--chakra-fontWeights-bold);
        &:hover {
          background-color: $color-sidebar-hover-bg;
        }
      }
      .remove {
        color: $color-default-danger;
      }
    }
  }
}

.heading {
  font-size: var(--chakra-fontSizes-4xl);
}

.access-management-remove-app-modal {
  width: 666px !important;
  .app-modal-body {
    padding: var(--chakra-space-7) !important;
  }
  .app-modal-title {
    font-size: var(--chakra-fontSizes-3xl) !important;
    font-weight: var(--chakra-fontWeights-semibold) !important;
    padding: var(--chakra-space-8) var(--chakra-space-5) 0 var(--chakra-space-5) !important;
  }
  footer {
    gap: var(--chakra-space-10) !important;
    margin-top: 0 !important;
  }
  .chakra-modal__body {
    font-size: $font-size-reg !important;
  }
  .radio-button {
    .radio-box {
      width: 100%;
    }
  }
}
