@import "_variables.scss";
.doc-card-wrapper {
  border-bottom: 1px solid $color-border;
  padding: var(--chakra-space-9-6) var(--chakra-space-4-8)
    var(--chakra-space-9-6) var(--chakra-space-6-4);
  background-color: $color-default-white;
  width: 100%;
  img {
    width: 2.4rem;
    height: 2.4rem;
    align-self: flex-start;
    max-width: none;
  }
  .doc-card-content {
    display: flex;
    padding-top: var(--chakra-space-1-6);
    margin-left: var(--chakra-space-4-8);
    flex-direction: column;
    gap: 6px;
    .title {
      font-size: $font-size-med;
      font-weight: var(--chakra-fontWeights-semibold);
    }
    .sub-title {
      font-size: $font-size-reg;
      font-weight: var(--chakra-fontWeights-normal);
    }
  }
}
.doc-card-wrapper:hover {
  border-bottom: 1px solid $color-border;
  padding: var(--chakra-space-9-6) var(--chakra-space-4-8)
    var(--chakra-space-9-6) var(--chakra-space-6-4);
  background-color: $color-bg-badge;
}

@media screen and (max-width: $breakpoint-mobile-desktop) {
  .doc-card-wrapper {
    border-radius: 8px;
    border-width: 1px;
    .doc-card-content {
      margin-left: 0;
    }
  }
}
