@import "_variables.scss";

.trans-code-landing-page {
  .toast-conatiner {
    width: calc(var(--chakra-sizes-8xl) + var(--chakra-sizes-2));
    margin: 0 auto;
    padding: 0;
    border-radius: var(--chakra-radii-xl);
    border: 1px solid $color-stepper-status-inprogress;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--chakra-space-4);
    .toast-icon {
      width: 7%;
      border-top-left-radius: var(--chakra-radii-xl);
      border-bottom-left-radius: var(--chakra-radii-xl);
      background: $color-stepper-status-inprogress;
      img {
        margin: var(--chakra-space-10) auto;
      }
    }
    .toast-text {
      width: 75%;
      h5 {
        font-size: $font-size-med;
        font-weight: var(--chkara-fontWeights-semibold);
      }
      p {
        font-size: $font-size-reg;
        font-weight: var(--chkara-fontWeights-normal);
        color: $color-font-card;
      }
    }
    .toast-divider {
      border: 1px solid $color-border;
      font-weight: var(--chkara-fontWeights-bold);
    }
    .toast-close {
      border-left: 1px solid $color-border;
      .close-icon {
        width: var(--chakra-sizes-6);
        height: var(--chakra-sizes-6);
        margin: var(--chakra-space-8);
      }
      .close-icon:hover {
        color: $color-link-hover-color;
      }
    }
  }

  .text-centered {
    margin: 0 auto;
    width: 604px;
    height: 327px;
    padding: var(--chakra-sizes-12);
    background: $color-main-bg-color;
    gap: 8px;
    .heading-text {
      width: 488px;
      font-family: $font-family-regular !important;
      font-size: $font-size-l;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
    }
    .paragraph-text {
      width: 540px;
      height: 129px;
      padding: var(--chakra-sizes-9);
      border-radius: 16px;
      border: 1px solid $color-border;
      .paragraph-heading {
        font-family: $font-family-sfmono;
        font-weight: 500;
        text-align: center;
      }
      .paragraph {
        font-family: $font-family-regular;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-align: center;
      }
    }
  }
  .resource-heading {
    text-align: left;
    font-family: $font-family-regular;
    font-size: $font-size-2rem;
    font-weight: bold;
    line-height: var(--chakra-space-11);
    margin-top: var(--chakra-space-36);
    width: 65%;
  }

  .resource-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    margin-top: var(--chakra-space-10);
    width: 65%;
    color: $color-default-font-color;
    font-family: $font-family-regular;
    .resource-title {
      font-weight: 600;
      letter-spacing: 0.01em;
    }
    .resource-caption {
      font-size: $font-size-12px;
      font-weight: 400;
      color: $color-stepper-indicator;
    }
    .resource-arrow {
      margin: var(--chakra-space-2) var(--chakra-space-5) var(--chakra-space-10)
        0px;
    }
    .resource-body {
      margin: var(--chakra-space-2) 0px var(--chakra-space-2)
        var(--chakra-space-6);
    }
  }

  .bottom-divider {
    border: 1px solid $color-border;
    margin-top: var(--chakra-space-32);
  }
  .transaction-codes-container {
    padding: var(--chakra-space-8) 0;
    hr {
      border-color: $color-gradient-light-orange;
    }
    .in-progress-container {
      border: 1px solid $color-border;
      border-radius: 8px;
      padding: var(--chakra-space-8);
      font-size: $font-size-reg;
      .in-progress-desc {
        h3 {
          font-size: $font-size-2rem;
        }
      }
    }
    .menu-list-container {
      border-radius: 16px;
      font-size: $font-size-reg;
      .delete-btn {
        color: $color-default-danger;
        font-weight: 700;
      }
      button {
        padding: var(--chakra-space-6-4);
        font-size: $font-size-reg;
        font-weight: var(--chakra-fontWeights-bold);
        &:hover {
          background-color: $color-sidebar-hover-bg;
          border-radius: $border-radius-small;
        }
        &:focus {
          background-color: $color-sidebar-hover-bg;
          border-radius: $border-radius-small;
        }
      }
    }
    .in-progress-header {
      text-transform: uppercase;
      font-size: $font-size-small;
      margin-bottom: var(--chakra-space-2);
      font-family: $font-family-sfmono;
      margin-left: var(--chakra-space-13);
      margin-top: var(--chakra-space-5);
    }
    .no-transactions-found {
      h3 {
        font-size: $font-size-reg;
        font-family: $font-family-regular !important;
      }
    }
  }
}
