@import "_variables.scss";

.doc-explore-container {
  .inner-container {
    gap: var(--chakra-space-12-8);
    
    .doc-heading {
      font-family: $font-family-semi-bold;
      font-size: $font-size-l;
      font-weight: var(--chakra-fontWeights-semibold);
    }
    .doc-sub-heading {
      font-size: $font-size-reg;
      font-weight: var(--chakra-fontWeights-normal);
      color: $color-font-card;
      margin-bottom: 0px;
    }
    .card {
      border: 1px solid $color-border;
      box-shadow: none;
      border-radius: 8px;

      .mobile-icon {
        color: $fast-icon-0;
      }

      .chakra-card__footer {
        .icon-link-button {
          a > button > span {
            margin-inline-start: var(--chakra-space-6);
          }
        }
      }
      .featured-doc-desc {
        color: $fast-gray-2;
      }
    }
  }
  .chakra-card__body {
    padding: 0px;
  }
  .chakra-card {
    padding: 24px;
    .chakra-card__footer {
      padding: 0px;
      margin-top: 16px;
    }
  }
}
