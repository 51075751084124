@import "_variables.scss";

.profile-template-container {
  .profile-template-form-container {
    width: 100%;
    &.environment {
      margin-bottom: var(--chakra-space-24);
    }
  }
  .environment-container {
    margin-bottom: var(--chakra-space-12-8);
    margin-top: var(--chakra-space-5);
    &.is-last{
      border-bottom: none;
    }
    .environment-heading {
      gap: var(--chakra-space-4);
      margin-bottom: var(--chakra-space-8);
      h3 {
        font-size: $font-size-med;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 0;
      }
    }
  }
}
