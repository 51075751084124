@import "_variables.scss";

.container {
  position: relative;
}

.horizontalLineTop,
.horizontalLineBottom {
  border: 1px solid $color-border;
}

.horizontalLineTop {
  position: absolute;
  width: 100%;
  top: 0;
}


.descriptionStack {
  margin: $padding-none;
}

.titleText {
  font-family: $font-family-regular;
  font-size: $font-size-med;
  font-weight: $font-weight-600;
  line-height: $line-height-large;
  text-align: left;
  display: flex;
  gap: $gap-xs;
  align-items: center;
  padding-top: $padding-medium;
}

.description {
  font-weight: $font-weight-medium;
  font-size: $font-size-reg;
  line-height: $line-height-small;
}

.imageStyle {
  max-width: $course_img_width;
  max-height: $course_img_height;
  margin: $padding-small;
}

.card {
  background: $color-default-white;
  box-shadow: $box-shadow-default;
  border-radius: $border-radius-medium;
  margin: var(--chakra-space-9-6) 0;
  padding: $padding-medium;
}
