@import "_variables.scss";

.clover-checkout-container {
  .field {
    margin-bottom: var(--chakra-space-9-6);
    height: $field_height;
    margin-right: var(--chakra-space-3-2);

    input {
      width: 100%;
      padding: $padding-small;
      border: 1px solid $color-border;
      border-radius: $border-radius-xs;
    }

    iframe:nth-child(2) {
      display: none;
    }
  }
  .content-layout {
    width: 100%;
    justify-content: space-between;
  }

  .payment-course-details {
    gap: $gap-small;

    .course-name {
      border: 1px solid $color-font-card;
      border-radius: $border-radius-16;
      width: 288px;
      height: 141px;
      padding: $padding-small;

      img {
        width: $icon_width;
        height: $icon_height;
      }
    }
  }
}
.payment-popup-header {
  p {
    font-size: $font-size-reg;
  }
}
